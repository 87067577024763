@import url(../fonts/din/stylesheet.css);
@import url(../fonts/georgia/stylesheet.css);
@import url(../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css);
@import url(../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css);
div,
h1,
h2,
h3,
h4,
h5,
hgroup,
article,
section,
figure,
textarea,
svg,
img,
label,
input,
form,
button,
main,
aside,
nav,
a,
header,
footer,
span,
p,
strong,
ul,
li,
ol,
body,
html,
footer,
i,
table,
tr,
td {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-weight: normal;
  border: 0;
  outline: 0;
  color: #303030;
  font-family: "Georgia";
  line-height: 1;
}

html {
  font-size: 1vw;
}

@media (max-width: 640px) {
  html {
    font-size: 4.3vw;
  }
}

.container {
  width: 100%;
}

@media (max-width: 640px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container .wrapper {
  width: 62.5%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6.25vw;
  padding-bottom: 6.25vw;
}

@media (max-width: 640px) {
  .container .wrapper {
    width: 100%;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -10vw;
}

@media (max-width: 640px) {
  nav {
    margin-top: -56vw;
  }
}

nav .logo {
  width: 10vw;
}

@media (max-width: 640px) {
  nav .logo {
    width: 40%;
  }
}

nav .logo img {
  width: 100%;
}

nav .phons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

nav .phons a.phone-num {
  font-size: 1.875rem;
  font-family: "DIN Pro Cond";
  color: #fff;
  font-weight: bold;
}

nav .phons a.phone-num span {
  font-family: "DIN Pro Cond";
  color: #fff;
  font-size: 1.25rem;
}

nav .phons .call-back {
  font-size: 0.9375rem;
  line-height: 64%;
  text-align: right;
  text-decoration-line: underline;
  color: #fff;
  margin-top: .55vw;
  font-family: "DIN Pro";
}

@media (max-width: 640px) {
  nav .phons .call-back {
    margin-top: 5vw;
  }
}

nav .phons .call-back:hover {
  text-decoration: none;
}

.prewiev-block {
  width: 100%;
  height: 100vh;
  min-height: 56.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.h1-def {
  color: #fff;
  position: relative;
  font-family: "Poiret One", sans-serif;
  font-size: 13rem;
  color: #fff;
  text-transform: uppercase;
  line-height: 84%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.25vw;
  margin-top: -2.2vw;
}

@media (max-width: 640px) {
  .h1-def {
    font-size: 4rem;
  }
}

.h1-def:before {
  content: attr(data-content-before);
  color: #FCB400;
  position: absolute;
  font-family: "Georgia";
  font-style: italic;
  font-weight: normal;
  font-size: 7.5rem;
  top: 20%;
  left: 46%;
}

@media (max-width: 640px) {
  .h1-def:before {
    font-size: 3rem;
  }
}

.h1-def:after {
  content: attr(data-content-after);
  color: #fff;
  position: absolute;
  font-family: "Georgia";
  font-style: italic;
  font-weight: normal;
  font-size: 1.875rem;
  top: 6%;
  left: 0%;
  text-transform: lowercase;
  font-weight: bold;
}

@media (max-width: 640px) {
  .h1-def:after {
    font-size: .8rem;
    top: 7%;
  }
}

.desc {
  font-size: 1.875rem;
  color: #fff;
  font-style: italic;
  padding-bottom: 2.25vw;
}

@media (max-width: 640px) {
  .desc {
    font-size: 1.6rem;
    text-align: center;
  }
}

.desc strong {
  font-weight: bold;
  color: inherit;
}

.btn-def {
  background-color: #ffbd17;
  color: #fff;
  font-family: "DIN Pro Cond";
  text-transform: uppercase;
  padding-top: 1.042vw;
  padding-bottom: 1.042vw;
  padding-left: 2.084vw;
  padding-right: 2.084vw;
  letter-spacing: 0.24em;
  border-radius: 2px;
}

@media (max-width: 640px) {
  .btn-def {
    width: 80%;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    margin-top: 16px;
  }
}

.btn-def:hover {
  background-color: #FCB400;
}

.btn-def.orange {
  background: #f96921;
}

.btn-def.orange:hover {
  background-color: #F85808;
}

.top-title {
  font-size: 1.875rem;
  text-transform: uppercase;
  font-family: "DIN Pro Cond";
  color: #fff;
  padding-bottom: 3.25vw;
  text-align: center;
}

.title-block {
  text-transform: lowercase;
  color: #303030;
  font-size: 5rem;
  font-family: "Poiret One", sans-serif;
  text-align: center;
  padding-bottom: 2.25vw;
}

@media (max-width: 640px) {
  .title-block {
    font-size: 2.6rem;
  }
}

.catalog_items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: normal;
  flex-wrap: wrap;
}

.catalog_items .col {
  width: 33.33333333%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 1vw;
  padding-right: 1vw;
}

@media (max-width: 640px) {
  .catalog_items .col {
    width: 100%;
  }
}

.catalog_items .col .item {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border: .1vw solid transparent;
  padding-top: 0.8375em;
  padding-bottom: 1.668em;
  cursor: default;
}

@media (max-width: 640px) {
  .catalog_items .col .item {
    padding-top: 16px;
    padding-bottom: 16px;
    border-width: 2px;
  }
}

.catalog_items .col .item img {
  width: 100%;
  transform: scale(0.95);
  transition: all .2s ease;
  margin-bottom: .55vw;
}

@media (max-width: 640px) {
  .catalog_items .col .item img {
    margin-bottom: 10px;
  }
}

.catalog_items .col .item .title-item {
  color: #303030;
  font-size: 1.875rem;
  line-height: 64%;
  font-family: "DIN Pro Cond";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25vw;
  margin-top: auto;
}

.catalog_items .col .item .price {
  color: #303030;
  font-size: 1.25rem;
  line-height: 164%;
  font-family: "DIN Pro Cond";
  font-weight: bold;
  text-transform: uppercase;
}

.catalog_items .col .item .to_date {
  font-family: "Georgia";
  font-size: 0.9375rem;
  line-height: 56%;
  text-align: center;
  color: #FCB400;
  font-style: italic;
}

.catalog_items .col .item:hover {
  border: .1vw solid #F0F0F0;
}

@media (max-width: 640px) {
  .catalog_items .col .item:hover {
    border-width: 2px;
  }
}

.catalog_items .col .item:hover img {
  transform: scale(1);
}

.content-simple {
  padding-top: 4.4vw;
  padding-left: 1.1vw;
}

.combo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4.4vw;
}

@media (max-width: 640px) {
  .combo {
    flex-wrap: wrap;
  }
}

.combo a[href^="tel"] {
  font-size: 1.875rem;
  font-family: "DIN Pro Cond";
  display: inline-block;
  font-weight: bold;
  padding-top: 1.1vw;
}

.combo a[href^="tel"] span {
  font-size: 1.25rem;
  font-family: "DIN Pro Cond";
  font-weight: 400;
  padding-top: 1.1vw;
}

@media (max-width: 640px) {
  .combo a[href^="tel"] {
    padding-top: 4.4vw;
    margin-bottom: 4.4vw;
  }
}

.combo a[href^="mailto"] {
  font-family: "DIN Pro";
  display: inline-block;
  font-size: 1.25rem;
  line-height: 128%;
  font-weight: 400;
  padding-top: 1.1vw;
  color: #F85808;
}

@media (max-width: 640px) {
  .combo a[href^="mailto"] {
    padding-top: 4.4vw;
    margin-bottom: 16.8vw;
  }
}

.combo .p-default {
  font-size: 0.9375rem;
  font-family: "DIN Pro";
  font-weight: 400;
  padding-top: 1.1vw;
  width: 70%;
}

@media (max-width: 640px) {
  .combo .p-default {
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
  }
}

.combo .p-default.fluid {
  width: 100%;
  padding-right: 0;
  margin-top: -2.2vw;
  font-size: 1.25rem;
}

@media (max-width: 640px) {
  .combo .p-default.fluid {
    padding-top: 4.4vw;
    margin-bottom: 4.4vw;
    margin-top: 0;
  }
}

.combo .left {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -3.3vw;
}

@media (max-width: 640px) {
  .combo .left {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .combo .left .desc {
    text-align: left !important;
  }
}

.combo .left .big-count {
  font-size: 13rem;
  color: #FCB400;
  font-family: "Poiret One", sans-serif;
  font-weight: 300;
}

.combo .right {
  width: 50%;
}

@media (max-width: 640px) {
  .combo .right {
    width: 100%;
  }
}

.combo .right .slider {
  min-height: 20vw;
  width: 100%;
  background: #E8E8E8;
  position: relative;
}

@media (max-width: 640px) {
  .combo .right .slider {
    min-height: 100vw;
    margin-top: 6.6vw;
    background-color: transparent;
  }
}

.combo .right .slider iframe {
  min-height: 20vw;
}

@media (max-width: 640px) {
  .combo .right .slider iframe {
    min-height: 100vw;
    margin-top: -10vw;
  }
}

footer {
  width: 100%;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  background-color: #E8E8E8;
}

@media (max-width: 640px) {
  footer {
    padding-top: 6.6vw;
    padding-bottom: 6.6vw;
  }
}

footer .container .wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

#slider {
  width: 100%;
  min-height: 100%;
  position: relative;
}

#slider .item-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 20vw;
}

@media (max-width: 640px) {
  #slider .item-custom {
    min-height: 100vw;
  }
}

#slider img {
  min-height: 100%;
}

#slider .nav-custom {
  width: 2.2vw;
  height: 2.2vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
}

#slider .nav-custom:before {
  content: '';
  animation: pulse 5s ease-in infinite both;
  width: 1vw;
  height: 1vw;
  background-color: #ffd263;
  position: absolute;
  border-radius: 26px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(3);
  }
}

#slider .nav-custom-prev {
  left: 100%;
  bottom: 0;
}

#slider .nav-custom-next {
  right: 0;
  top: 100%;
}

#slider .nav-custom span {
  color: #E8E8E8;
  font-size: 1.875rem;
  line-height: 0;
  transform: translateY(-2px);
}

#slider .nav-custom:hover {
  background-color: #FCB400;
}

#slider .nav-custom:hover:before {
  display: none;
}

#slider .nav-custom:hover span {
  color: #fff;
}

.copy {
  font-family: "DIN Pro";
  font-size: 1.25rem;
  line-height: 128%;
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.yellow {
  color: #FCB400;
}

.bold {
  font-weight: bold;
}

.wrapper-modal {
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.wrapper-modal.active {
  z-index: 99999999;
  display: flex;
}

.modal {
  width: 20%;
  position: relative;
  background-color: #fff;
  padding: 2.2vw;
}

.modal .title {
  width: 100%;
  font-family: "DIN Pro Cond";
  font-size: 1.875rem;
  text-align: center;
  display: inline-block;
  margin-bottom: 1.1vw;
  text-transform: uppercase;
  font-weight: 500;
}

.modal .title strong {
  color: #F85808;
  font-family: "DIN Pro Cond";
  font-size: 1.875rem;
  font-weight: bold;
}

.modal form {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 640px) {
  .modal form {
    width: 100%;
    margin-top: 30px;
  }
}

.modal form input {
  box-sizing: border-box;
  padding-top: 1.042vw;
  padding-bottom: 1.042vw;
  padding-left: 1.084vw;
  padding-right: 1.084vw;
  text-align: center;
  margin-bottom: 1.1vw;
  border: .1vw solid #F0F0F0;
  width: 100%;
  font-size: 0.9375rem;
  line-height: 64%;
  letter-spacing: 0.24em;
  color: #303030;
  font-family: "DIN Pro Cond";
}

@media (max-width: 640px) {
  .modal form input {
    margin-top: 20px;
    padding: 20px;
    font-size: 1.5rem;
  }
}

.modal form input::placeholder {
  font-size: 0.9375rem;
  letter-spacing: 0.24em;
  font-family: "DIN Pro Cond";
  color: #808080;
}

@media (max-width: 640px) {
  .modal form input::placeholder {
    font-size: 1.5rem;
  }
}

.modal form button {
  padding-top: 1.042vw;
  padding-bottom: 1.042vw;
  padding-left: 2.084vw;
  padding-right: 2.084vw;
  width: 100%;
  font-size: 0.9375rem;
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 640px) {
  .modal form button {
    margin-top: 20px;
    padding: 20px;
    font-size: 1.5rem;
  }
}

.close {
  color: #FCB400;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: bold;
  position: absolute;
  bottom: 100%;
  left: 101%;
}

@media (max-width: 640px) {
  .close {
    top: 0px;
    right: 16px;
    left: auto;
    bottom: auto;
    color: #303030;
    font-size: 4.16667rem !important;
  }
}

@media (max-width: 640px) {
  .wrapper-modal {
    height: auto;
    min-height: 100vh;
    align-items: flex-start;
    overflow-y: scroll;
  }
  .modal {
    min-height: 100vh;
    width: 100%;
    border-radius: 0;
    padding: 16px;
    padding-top: 64px;
  }
  .close {
    font-size: 32px;
    top: 12px;
  }
}
