/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:35 AM */

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro-Medium.eot');
	src: local('DIN Pro Medium'), local('DINPro-Medium'),
		url('DINPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('DINPro-Medium.woff') format('woff'),
		url('DINPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Cond';
	src: url('DINPro-CondensedMedium.eot');
	src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
		url('DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
		url('DINPro-CondensedMedium.woff') format('woff'),
		url('DINPro-CondensedMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro-Bold.eot');
	src: local('DIN Pro Bold'), local('DINPro-Bold'),
		url('DINPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('DINPro-Bold.woff') format('woff'),
		url('DINPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro-Light.eot');
	src: local('DIN Pro Light'), local('DINPro-Light'),
		url('DINPro-Light.eot?#iefix') format('embedded-opentype'),
		url('DINPro-Light.woff') format('woff'),
		url('DINPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro.eot');
	src: local('DIN Pro'), local('DINPro'),
		url('DINPro.eot?#iefix') format('embedded-opentype'),
		url('DINPro.woff') format('woff'),
		url('DINPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Cond';
	src: url('DINPro-CondensedBlack.eot');
	src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
		url('DINPro-CondensedBlack.eot?#iefix') format('embedded-opentype'),
		url('DINPro-CondensedBlack.woff') format('woff'),
		url('DINPro-CondensedBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Cond';
	src: url('DINPro-CondensedBold.eot');
	src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
		url('DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
		url('DINPro-CondensedBold.woff') format('woff'),
		url('DINPro-CondensedBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('DINPro-Black.eot');
	src: local('DIN Pro Black'), local('DINPro-Black'),
		url('DINPro-Black.eot?#iefix') format('embedded-opentype'),
		url('DINPro-Black.woff') format('woff'),
		url('DINPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Cond';
	src: url('DINPro-CondensedRegular.eot');
	src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
		url('DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype'),
		url('DINPro-CondensedRegular.woff') format('woff'),
		url('DINPro-CondensedRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Cond';
	src: url('DINPro-CondensedLight.eot');
	src: local('DIN Pro Condensed Light'), local('DINPro-CondensedLight'),
		url('DINPro-CondensedLight.eot?#iefix') format('embedded-opentype'),
		url('DINPro-CondensedLight.woff') format('woff'),
		url('DINPro-CondensedLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
