@font-face {
    font-family: 'Georgia';
    src: url('Georgia.woff2') format('woff2'),
        url('Georgia.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-Bold.woff2') format('woff2'),
        url('Georgia-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-Italic.woff2') format('woff2'),
        url('Georgia-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-BoldItalic.woff2') format('woff2'),
        url('Georgia-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

